import React from 'react'
import { Link } from 'gatsby'
import logo from "../../assets/images/newLogo.png"
import footerMap from "../../assets/images/footer-map.png"

const Footer = () => {

    const currentYear = new Date().getFullYear();

    return (
        <footer className="footer-area bg-color">
            <div className="container">
                <div className="row">
                    <div className="col-lg-4 col-sm-6">
                        <div className="single-footer-widget">
                            <a href="/" className="logo">
                                <img style={{
                                    height: "65px",
                                    objectFit: "contain"
                                }} src={logo} alt="logo" />
                            </a>
                            <p>Software Development, Website Design, Graphic Design, Social Media Marketing, Bulk SMS/Email, Video Editing, Support to Offshore Clients and More...</p>

                            <ul className="social-link">
                                <li>
                                    <Link to="https://www.facebook.com/koilitech" className="d-block" target="_blank" rel="noreferrer">
                                        <i className='bx bxl-facebook'></i>
                                    </Link>
                                </li>
                                <li>
                                    <Link to="https://www.instagram.com/koilitech/" className="d-block" target="_blank" rel="noreferrer">
                                        <i className='bx bxl-instagram'></i>
                                    </Link>
                                </li>
                                <li>
                                    <Link to="https://www.linkedin.com/company/koilitech/" className="d-block" target="_blank" rel="noreferrer">
                                        <i className='bx bxl-linkedin'></i>
                                    </Link>
                                </li>
                            </ul>
                        </div>
                    </div>

                    <div className="col-lg-4 col-sm-6">
                        <div className="single-footer-widget pl-5">
                            <h3>Explore</h3>

                            <ul className="footer-links-list">
                                <li>
                                    <Link to="/">
                                        Home
                                    </Link>
                                </li>
                                <li>
                                    <Link to="/about-us">
                                        About
                                    </Link>
                                </li>
                                <li>
                                    <Link to="/contact">
                                        Contact
                                    </Link>
                                </li>
                            </ul>
                        </div>
                    </div>

                    <div className="col-lg-4 col-sm-6">
                        <div className="single-footer-widget">
                            <h3>Address</h3>

                            <ul className="footer-contact-info">
                                <li>
                                    <i className='bx bx-map'></i>
                                    1309 Coffeen Avenue STE 1200, Sheridan, Wyoming 82801
                                </li>
                                <li>
                                    <i className='bx bx-phone-call'></i>
                                    <a href="tel:+13107765998">+1 (310) 776 5998</a>
                                </li>
                                <li>
                                    <i className='bx bx-envelope'></i>
                                    <a href="mailto:info@koilitech.com">info@koilitech.com</a>
                                </li>
                            </ul>
                        </div>
                    </div>

                    {/*<div className="col-lg-3 col-sm-6">*/}
                    {/*    <div className="single-footer-widget">*/}
                    {/*        <h3>Address (Nepal)</h3>*/}

                    {/*        <ul className="footer-contact-info">*/}
                    {/*            <li>*/}
                    {/*                <i className='bx bx-map'></i>*/}
                    {/*                Satdobato - 15, Lalitpur, Nepal 44700*/}
                    {/*            </li>*/}
                    {/*            <li>*/}
                    {/*                <i className='bx bx-phone-call'></i>*/}
                    {/*                <a href="tel:+13107765998">+977 (984) 930 8132</a>*/}
                    {/*            </li>*/}
                    {/*            <li>*/}
                    {/*                <i className='bx bx-envelope'></i>*/}
                    {/*                <a href="mailto:info@koilitech.com">info@koilitech.com</a>*/}
                    {/*            </li>*/}
                    {/*        </ul>*/}
                    {/*    </div>*/}
                    {/*</div>*/}
                </div>

                <div className="footer-bottom-area">
                    <div className="row align-items-center">
                        <div className="col-lg-6 col-md-6">
                            <p>Copyright @{currentYear} <strong>Koili Tech LLC</strong> All rights reserved</p>
                        </div>
                    </div>
                </div>
            </div>

            <div className="footer-map">
                <img src={footerMap} alt="footer-logo" />
            </div>
        </footer>
    );
}

export default Footer;
