import React from 'react'
import { Helmet } from "react-helmet"

const SEO = () => {
    return (
        <div>
            <Helmet>
                <title>Koili Tech - Complete IT Solution</title>
                <meta name="description" content="Koili Tech - Complete IT Solution. Software Development, Testing, Graphic Design, Social Media Marketing and more." />
                <meta name="og:title" property="og:title" content="Koili Tech - Complete IT Solution" />
                <meta property="og:image" content="https://public-assets-file.s3.amazonaws.com/koili-tech/images/banner.jpg" />
            </Helmet>
        </div>
    )
}

export default SEO
